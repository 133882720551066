import { Link } from 'gatsby';
import React, { useState, useEffect, useCallback } from 'react';

import Scroll from './Scroll';

const headers = [
  {
    element: 'features',
    link: '/#features',
    name: 'Features',
  },
  {
    element: 'download',
    link: '/#download',
    name: 'Download',
  },
  {
    link: '/contacts',
    name: 'Contact',
  },
];

const Header = ({
  pageOffset = 300,
  hideNav,
  scrollable,
  alwaysVisible = false,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const [visibilityClass, setVisibilityClass] = useState(alwaysVisible ? 'navbar-shrink' : '');

  const handleScroll = useCallback(() => {
    if (alwaysVisible || window.pageYOffset > pageOffset) {
      setVisibilityClass('navbar-shrink');
    } else {
      setVisibilityClass('');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
      id="mainNav"
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <div className="logo" />
        </Link>
        <button
          onClick={_ => setOpenMenu(!openMenu)}
          className={`navbar-toggler navbar-toggler-right ${
            openMenu ? '' : 'collapsed'
          }`}
          type="button"
          aria-controls="navbarResponsive"
          aria-expanded={openMenu}
          aria-label="Toggle navigation"
        >
          Menu
          <i className="fas fa-bars"></i>
        </button>

        <div
          className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
          id="navbarResponsive"
        >
          {!hideNav && (
            <ul className="navbar-nav ms-auto">
              {headers.map(header => {
                const inner = (
                  <Link className="nav-link" to={header.link} key={header.name}>
                    {header.name}
                  </Link>
                );
                return (
                  <li className="nav-item" key={header.name}>
                    {scrollable && header.element ? (
                      <Scroll
                        onClick={_ => setOpenMenu(!openMenu)}
                        type="id"
                        element={header.element}
                      >
                        {inner}
                      </Scroll>
                    ) : (
                      inner
                    )}
                  </li>
                );
              })}
              {/* 
              <li className="nav-item">
                <Scroll
                  onClick={_ => setOpenMenu(!openMenu)}
                  type="id"
                  element="features"
                >
                  <Link className="nav-link" to="#features">
                    Features
                  </Link>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => setOpenMenu(!openMenu)}
                  type="id"
                  element="download"
                >
                  <Link className="nav-link" to="#download">
                    Download
                  </Link>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => setOpenMenu(!openMenu)}
                  type="id"
                  element="contact"
                >
                  <Link className="nav-link" to="#contact">
                    Contact
                  </Link>
                </Scroll>
              </li>
              */}
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;

// export default class Header extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       openMenu: false,
//       visibilityClass: '',
//       pageOffset: props.pageOffset ?? 300,
//     };
//   }
//   toggleMenu = value => {
//     this.setState({ openMenu: value });
//   };

//   handleScroll = () => {
//     const { visibilityClass } = this.state;
//     if (window.pageYOffset > this.state.pageOffset) {
//       if (visibilityClass !== 'navbar-shrink') {
//         this.setState({ visibilityClass: 'navbar-shrink' });
//       }
//     } else {
//       if (visibilityClass === 'navbar-shrink') {
//         this.setState({ visibilityClass: '' });
//       }
//     }
//   };
//   componentDidMount() {
//     window.addEventListener('scroll', this.handleScroll);
//   }
//   componentWillUnmount() {
//     window.removeEventListener('scroll', this.handleScroll);
//   }

//   render() {
//     const { openMenu, visibilityClass } = this.state;
//     return (
//       <nav
//         className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
//         id="mainNav"
//       >
//         <div className="container">
//           <Link className="navbar-brand" to="/">
//             <div className="logo" />
//           </Link>
//           <button
//             onClick={_ => this.toggleMenu(!openMenu)}
//             className={`navbar-toggler navbar-toggler-right ${
//               openMenu ? '' : 'collapsed'
//             }`}
//             type="button"
//             aria-controls="navbarResponsive"
//             aria-expanded={openMenu}
//             aria-label="Toggle navigation"
//           >
//             Menu
//             <i className="fas fa-bars"></i>
//           </button>

//           <div
//             className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
//             id="navbarResponsive"
//           >
//             {!this.props.hideNav && (
//               <ul className="navbar-nav ml-auto">
//                 <li className="nav-item">
//                   <Scroll
//                     onClick={_ => this.toggleMenu(!openMenu)}
//                     type="id"
//                     element="features"
//                   >
//                     <Link className="nav-link" to="#features">
//                       Features
//                     </Link>
//                   </Scroll>
//                 </li>
//                 <li className="nav-item">
//                   <Scroll
//                     onClick={_ => this.toggleMenu(!openMenu)}
//                     type="id"
//                     element="download"
//                   >
//                     <Link className="nav-link" to="#download">
//                       Download
//                     </Link>
//                   </Scroll>
//                 </li>
//                 <li className="nav-item">
//                   <Scroll
//                     onClick={_ => this.toggleMenu(!openMenu)}
//                     type="id"
//                     element="contact"
//                   >
//                     <Link className="nav-link" to="#contact">
//                       Contact
//                     </Link>
//                   </Scroll>
//                 </li>
//               </ul>
//             )}
//           </div>
//         </div>
//       </nav>
//     );
//   }
// }
